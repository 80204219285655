import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Switch } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";

import Message from "../utils/Message";
import FormGroup from "./utils/FormGroup";
import { backendApiUrl } from "../utils";

const Email = (props) => {
  const MDID = useParams().MDID;

  const [msg, setMsg] = useState({});

  const [emailStatus, setEmailStatus] = useState(
    props.module.data.email_status
  );

  const [validate, setValidate] = useState({});

  const [sendEmail, setSendEmail] = useState(
    props.module?.data?.email_status &&
      ["send_email", "awaiting_verification"].includes(
        props.module.data.email_status
      )
  );

  const onActivateOrDeactivateFromEmail = async (status) => {
    setSendEmail(status);
    await axios
      .post(
        backendApiUrl("aws/setverifyfromemail"),
        { status, MDID, email: props.element.value },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMsg({
          status: 200,
          msg: res.data.message,
          show: true,
        });
        setEmailStatus(res.data.email_status);
      })
      .catch((err) => {});
  };

  const onBlurHandler = async (e) => {
    await axios
      .post(
        backendApiUrl("emails/validate_email"),
        { email: e.target.value },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setValidate(res.data);
      });
  };

  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <>
          {msg.show && (
            <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
          )}
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-envelope"></i>
              </span>
            </div>
            <input
              placeholder=""
              type="text"
              id={props.element.id}
              onChange={props.events.change}
              onBlur={onBlurHandler}
              value={props.element.value}
              disabled={props.element.readonly ? true : false}
              className={"form-control " + props.element.class}
            />
          </div>
          {props.element.id === "email" &&
            props.user &&
            props.user.type === "Workforces" &&
            props.user._id === MDID && (
              <div className="mt-2">
                Send Email from This Address?
                <Switch
                  name="email_status"
                  checked={sendEmail}
                  onChange={(e) => {
                    onActivateOrDeactivateFromEmail(
                      e.target.checked ? true : false
                    );
                  }}
                />
                {emailStatus === "awaiting_verification" && (
                  <span className="text-danger">
                    <br />
                    Email is waiting for verification. Please check your inbox.
                  </span>
                )}
              </div>
            )}
        </>
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
      {validate.valid === false && (
        <Modal show={true} size="md" backdrop="static" keyboard={false}>
          <div align="center" className="p-5">
            <p>
              Your email has a problem: {validate.reason}. Would you like to
              continue? If so, our system will ignore all email services.
            </p>
            <button
              className="btn btn-sm btn-success mr-2 mt-2"
              onClick={() => {
                setValidate({});
                document.getElementById(props.element.id).focus();
              }}
            >
              Correct
            </button>
            <button
              className="btn btn-sm btn-danger mt-2"
              onClick={() => setValidate({})}
            >
              Proceed
            </button>
          </div>
        </Modal>
      )}
    </FormGroup>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Email);
