import React from "react";
import FormGroup from "../utils/FormGroup";
import Select from "react-select";

const Status = (props) => {
  let options = [];

  props.module.status &&
    props.module.status.forEach((s) => {
      s.value = s._id;
      s.label = s.title;
      options = [...options, s];
    });

  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <Select
          className={"kt-width-full" + props.element.class}
          id={props.element.id}
          options={options}
          value={props.element.value ? props.element.value : []}
          onChange={(e) => props.events.change(e, props.element.id)}
          isMulti
          placeholder="Please select"
        />
      ) : (
        <h6 className="text-primary">
          {props.element.value &&
            props.element.value.map((v, i) =>
              i === 0 ? v.title : ", " + v.title
            )}
        </h6>
      )}
    </FormGroup>
  );
};

export default Status;
