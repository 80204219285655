import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { backendApiUrl } from "../utils";

const AssignedContacts = (props) => {
  const [edit, setEdit] = useState(false);
  let ts = [];
  let contactTitle = props.contactTitle;

  props.asUsers &&
    props.asUsers.forEach((u) => {
      let label = "";
      let value = "";

      if (u.user && u.role) value = u.user._id + "---" + u.role._id;
      if (u.user && !!u.user.info.first_name) label = u.user.info.first_name;
      if (u.user && !!u.user.info.last_name)
        label += " " + u.user.info.last_name;
      if (u.role) label += " (" + u.role.title + ")";

      ts = [...ts, { value, label }];
    });
  const [users, setUsers] = useState(ts);

  const onSelectHandler = (temp) => {
    setUsers(temp);
    if (temp === null) temp = [];

    let data = { adminInfo: { contacts: [] } };
    temp.forEach((u) => {
      data.adminInfo.contacts = [...data.adminInfo.contacts, u.value];
    });

    axios
      .patch(backendApiUrl("opportunities/" + props.data._id), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        props.setMsg({
          status: 200,
          msg: "Assigned " + contactTitle + " has been updated successfully..",
          show: true,
        });
      })
      .catch((err) => {
        props.setMsg({
          status: 500,
          msg:
            "Problem while updating assigned " +
            contactTitle +
            ", please try again.",
          show: true,
        });
      });
  };

  return (
    <div>
      <i
        className="fa fa-edit fa-lg text-primary mr-2"
        onClick={() => setEdit(edit ? false : true)}
        title="Click to update users"
      />
      {edit ? (
        <Select
          className="kt-width-full"
          name="users"
          options={props.users}
          isMulti
          onChange={onSelectHandler}
          value={users}
          isDisabled={props.data.readonly ? true : false}
          placeholder="Please select"
          menuPortalTarget={document.body}
        />
      ) : (
        <span>
          {users.map((s, i) => (
            <span key={i}>
              {s.label}
              <br />
            </span>
          ))}
        </span>
      )}
    </div>
  );
};

export default AssignedContacts;
